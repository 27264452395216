import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
ReactSession.setStoreType("localStorage");



 function Add_product() {
    const userId = ReactSession.get("userId");
    const userType = ReactSession.get("userType");
    const userName = ReactSession.get("userName");
    const navigate = useNavigate();
    const indexPath = '/';


    const [maincat, setMaincat] = useState({ name: '', id: '' });
    const [cat, setCat] = useState("");
    const [price, setPrice] = useState("");
    const [desc, setDesc] = useState(""); 
    const [packingcharge, setPackingcharge] = useState(""); 
    const [file, setFile] = useState(null); 
    const [category, setcategory] = useState(null); 
    const [stock, setStock] = useState(null); 
    const [error, setError] = useState('');
    console.log(maincat);


    useEffect(()=>{
        const fetchData = async () => {
        try {
            const response = await axios.get('https://projects.sninfoserv.com/fbh-api/category/');
            if(response.data.status === "success"){
                setcategory(response.data);
                console.log(response.data);
            }
        
           //navigate(`/product`);
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
    };
    fetchData();
}, []);




   

	 const handleMainncat = (event) => {
    const [name, id] = event.target.value.split(',');
    setMaincat({ name, id });
    };
    const handlecat = (event) => {
        setCat(event.target.value); // Changed setInput to setLink
    };

    const handleDesc = (event) => {
        setDesc(event.target.value); // Changed setInput to setLink
    };


    const handlePrice = (event) => {
        setPrice(event.target.value); // Changed setInput to setLink
    };

    const handlePackingcharge = (event) => {
        setPackingcharge(event.target.value); // Changed setInput to setLink
    };
    const handlestock = (event) => {
      setStock(event.target.value); // Changed setInput to setLink
    };

    const handleFile = (event) => {
        setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

   



    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('image', file);
        formData.append('maincat',maincat?.name);
        formData.append('cat', cat);
        formData.append('price', price);
        formData.append('packingcharge', packingcharge);
        formData.append('desc', desc);
        formData.append('stock', stock);
       
        
     console.log(maincat);
     console.log(cat);
       
        try {
            const response = await axios.post('https://projects.sninfoserv.com/fbh-api/addproduct/', formData);
            console.log(response.data);
           if(response.data.status === "success"){
            console.log(response.data);
            navigate(`/product`);
        }
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
       
    };

    
  return (
   
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
    <div class="card shadow-lg mx-2 mt-5 card-profile-bottom">
      <div class="card-body p-2">
        <div class="row gx-4">
          <div class="col-12">
               <div class="nav-wrapper position-relative end-0">
             
			  <div class="tab-content">
					
					 
        <div class="tab-pane fade show active" id="takeAway" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
             <form onSubmit={handleSubmit}>
              <div class="row">
              
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Main Category</label>
                    <select type="text"  class="form-control" placeholder="Enter Main Category..."  value={maincat.name === '' ? maincat.name :maincat[0]?.name}
                                                            onChange={handleMainncat} required>
                      <option value="" disabled>Select the Main Category</option>                                     
                      {category?.data?.filter(product => product.visibility === '0').map(product => (
                        <option key={product.main_id} value={`${product.maincat_name},${product.main_id}`}>
                             {product.maincat_name}
                        </option>
                    ))}

                    </select>
                  </div>
                </div>
               
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Product Name</label>
                    <select type="text" list="products" class="form-control" name="item" placeholder="Enter Category.."  value={cat}
                                                            onChange={handlecat} required >
                     <option value="" disabled>Select the Category</option>
                     {category?.subcategory
                                         ?.filter(product =>  product.main_catid === maincat.id && product.visibility === '0') // Filter based on the matching id
                                         .map(product => (
    <option key={product.cat_id} value={product.cat_name}>
      {product.cat_name}
    </option>
  ))}

                    </select>
                   
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Disc</label>
                    <input type="text" class="form-control" name="qty" placeholder="Enter Disc..." value={desc}
                                                            onChange={handleDesc} required />      
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..."  value={price}
                                                            onChange={handlePrice}   required />       
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Packing Charge</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Packing Charge..." value={packingcharge}
                                                            onChange={handlePackingcharge}  required />       
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Stock</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Stock..." value={stock}
                                                            onChange={handlestock}  required />       
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Image 1</label>
                    <input
                        className="form-control"
                        type="file"
                        name="image"
                        id="image"
                        accept="image/png, image/jpeg, image/jpg, image/pdf"
                        onChange={handleFile}
                    />      
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>
               
              </div>
              </form>
             
             
              {/* <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div> */}
              

                
            </div>
					   </div>

         
					   
					   
          </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">

      <Footer />
    </div>
  </main>

  </div>
  )
}
export default Add_product;