import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
import numeral from 'numeral';
import './test.css';
ReactSession.setStoreType("localStorage");
import logo from '../assets/img/logos/FBH new logo.png';

 function Editbill() {
    const navigate = useNavigate();
    const userId = ReactSession.get("userId");
    const userType = ReactSession.get("userType");
    const userName = ReactSession.get("userName");
    const urlParams = new URLSearchParams(window.location.search);
    const ORDERID = urlParams.get('id');
    const indexPath = '/';
  
    useEffect(() => {
      if (userId == 0 ) {
        navigate(indexPath);
      }
    }, [userId, navigate]);



    const [orderData, setOrderData] = useState([]);
    const [orders, setorders] = useState([]);
    const [formError, setError]=useState({});
    const [product, setproduct]=useState([]);
    
    console.log(orderData);



    const fetchProData = async () => {
        try {
          const response = await axios.get('https://projects.sninfoserv.com/fbh-api/products/');
          if (response.data.status === "success") {
            setproduct(response.data);
          } else {
            console.error(response.data);
          }
        } catch (error) {
          console.error('There was a problem with fetching data:', error);
          setError('Failed to fetch product data');
        } 
      };
      
      useEffect(() => {
        fetchProData();
      }, []);

 
    const fetchData = async () => {
      try {
        let response;
        if (userType == 1) {
          response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/orders/');
        } else if (userType == 2) {
          response = await axios.post('https://projects.sninfoserv.com/fbh-api/dine/orders/', { "userId":userId });
        }
        const filteredOrder = response.data.data.filter(order => order.order_id === ORDERID);
        setOrderData(filteredOrder);
      } catch (error) {
        // Handle error if needed
      }
    };
  
    useEffect(() => {
      fetchData();
  }, []);

  useEffect(() => {
    if (orderData.length > 0) {
      setcName(orderData[0].order_name);
      setcPhone(orderData[0].order_phone);
      setcAddress(orderData[0].order_address);
      setAmount(orderData[0].order_amount);
      setorders(orderData[0].order_item_details);
      setNextId(orderData[0].order_item_details.length);
    }
  }, [orderData]); 



  const [cName, setcName] = useState(orderData[0]?.order_name);
  const [cPhone, setcPhone] = useState('');
  const [cAddress, setcAddress] = useState('');
  const [item, setItem] = useState('');
  const [price, setPrice] = useState('');
  const [qty, setQty] = useState('0');
  const [amount, setAmount] = useState(0);
  const [nextId, setNextId] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

 


  const itemChange = (e) => {
    setItem(e.target.value);
    setPrice('');
    setQty('');
  };


  const qtyChange = (e) => {
    setQty(e.target.value);
      fetchPrice();
   // checkProductqty(item, e.target.value);
  };



  const fetchPrice = () => {
    // Refresh the page after printing
   const productId = product?.data.find(items => items.cat === item);
   // console.log("productId",productId);
   if (productId) {
     setPrice(productId?.price);
   } else {
     setPrice(null);
     // console.log('Open Product');
   }
   
   setAmount(amount + (price * qty));
 };



 const additem = () => {


    
    
    //alert(qty +' - '+stocks);
    // if (Number(qty) > Number(stocks) ) {
      //alert("Not enough stock available for the requested quantity.");
      // Prevent the default form submission behavior
     // event.preventDefault();

      // setHut(section);
      // setItem('');
      // setPrice('');
      // setQty('');
      // setArea(area); // This seems redundant, but keeping it for clarity
      // setUser(user); // This is also redundant if already set elsewhere
      // Update the total amount after the order is added
    // } else {
     // event.preventDefault();
     let newOrder;  
     
      if ( orderData[0]?.order_area === 'Outer_Table' ||  orderData[0]?.order_area === 'Floor') {
          newOrder = {
            id: nextId + 1,
            order_item: item,
            order_price: price,
            order_qty: qty,
              
          };
      } else {

        
          newOrder = {
              id: nextId + 1,
              order_item: item,
              order_price: price,
              order_qty: qty,
          };
      }
      
      // Now you can use `newOrder` here after the if-else block
      console.log("newOrder", newOrder);

  
  
      // Update the orders array with the new order
     // setOrderData([...orderData, newOrder]);
     setorders((prevData) => [
      ...prevData, // Spread the previous state (array)
      newOrder,    // Add the new order at the end
    ]);
    
      // Increment the nextId for the next order
      setNextId(nextId + 1);
  
      // Reset values after order submission
  
      setItem('');
      setPrice('');
      setQty('');
      setAmount(amount + (qty * price)); // Update the total amount after the order is added
    // }
  };


  useEffect(() => {
    if(orders.length > 0){
      const gst = 5;
      let subtotal = 0;
      let gTotal = 0;
      orders.forEach(order => {
        subtotal += ((order. order_price/(1+(gst/100))) *  order.qty);
        gTotal += (order. order_price * order.order_qty);
      })
      setGrandTotal(gTotal);
    }
  },[orders]);



  const handleOrder = async () => {
    const orderDatas = {
      orders: orders,
      orderId: ORDERID,
      cName: cName,
      cPhone: cPhone,
      cAddress: cAddress,
  }
    console.log('orderdata', orderDatas);
    try {
     const response = await axios.post('https://projects.sninfoserv.com/fbh-api/edit_order/', orderDatas, {
    headers: {
        'Content-Type': 'application/json'
    }
});
      
       
         handlePrint(generateTableContent());
        handleDeleteAll();
        if(orderData[0]?.order_area === "Take Away" || orderData[0]?.order_area === "Delivery"){
          setAmount(0);
          setcAddress("");
          setcPhone("");
          setcName("");
          setGrandTotal(0);
          setorders([]);
        }
     
    } catch (error) {
      // Handle error
      console.error('There was a problem with login:', error);
      setError('Invalid email or password');
  }
  };



  const handleDelete = (id) => {
    const updatedOrders = orders.filter((order) => order.id !== id);
    setorders(updatedOrders);
  };
  
  const handleDeleteAll = () => {
    setorders([]);
    setAmount(0);
    setcAddress("");
    setcPhone("");
    setcName("");
    setHut("");
    setGrandTotal(0);
    getToken();
  };

   useEffect(() => {
      if(orders.length > 0){
        const gst = 5;
        let subtotal = 0;
        let gTotal = 0;
        orders.forEach(order => {
          subtotal += ((order.order_price/(1+(gst/100))) *  order.order_qty);
          gTotal += (order.order_price * order.order_qty);
        })
        setSubTotal(subtotal);
        setGrandTotal(gTotal);
      }
    },[orders]);


  
  const handlePrint = (content) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('style', 'display: none;');
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(content);
    iframeDoc.close();
    iframe.contentWindow.print();
  };

  
  const generateTableContent = () => {
   
    

    const isDiscountPercentage = orderData[0].order_discount.includes('%'); 

    
  let discountedAmount;
  const discValue = parseFloat(orderData[0].order_discount.replace('%', ''));
  
  if (isDiscountPercentage) {
    discountedAmount = Number( orderData[0].order_amount * (discValue / 100) );  
  } else {
    discountedAmount = Number(discValue);  
  }
  
  console.log('Discount = ', discountedAmount);

  
    
  // Convert the order's date string to a Date object
  const orderDate = new Date(orderData[0].order_date);

  // Format the date to dd/mm/yyyy
  const day = String(orderDate.getDate()).padStart(2, '0');
  const month = String(orderDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = orderDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;



  
  
  const cgst = subTotal * (2.5 / 100);
  const sgst = subTotal * (2.5 / 100);
  const discountedAmounts = parseFloat(discountedAmount) || 0;
  const gTotal = Number(grandTotal) - Number(discountedAmounts) + Number(orderData[0].order_delivery);
     
  
  const discountRow = discountedAmount > 0 ? `
      <tr align="center">
        <td colspan="3" class="content right" width="10%">Discount</td>
        <td class="content right" width="10%">₹${Number(discountedAmount).toFixed(2)}</td>
      </tr>` : '';
  
    
      const deliveryRow = orderData[0].order_delivery > 0 ? `
      <tr align="center">
        <td colspan="3" class="content right" width="10%">Delivery</td>
        <td class="content right" width="10%">₹${Number(orderData[0].order_delivery).toFixed(2)}</td>
      </tr>` : '';



  const token = (orderData[0].order_area === 'Delivery' || orderData[0].order_area === 'TakeAway') ? `
    <div class="header">Token Number :${orderData[0].order_token}</div>` : '';

       
    
       // console.log(gTotal);
        if (orderData[0].order_area === 'TakeAway' || orderData[0].order_area === 'Delivery') {
          return `
          <html>
        <head>
          <title>Order Details</title>
          <link rel="stylesheet" href="styles.css">
            <title>Order Details</title>
            <style>
                 
                  body {
                    margin: 0px; 
                  }
                  .header {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 5px; 
                  }
                  .address {
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 0px; 
                  }
                  .content {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 10px;
                  }
                  .left {
                    text-align:left;
                  }
                  .right{
                    text-align:right;
                  }
                    @media print {
                 .page-break {
                   page-break-before: always; 
                   }
                  }
    
                </style>
        </head>
        <body>
    
    
         <!-- Order Header and Address -->
          <div class="header">KOT-${userName}</div>
          <div class="address">${formattedDate}</div><br>
         <div class="address1">${orderData[0].order_name}</div>
    
          <table align="center">
            ${orders.map(order => `
              <tr >
                <td class="content left" width="85%" style="font-size: 16px">${order.order_item}</td>
                <td class="content right"  style="font-size: 16px">${order.order_qty}</td>
              </tr>`).join('')}
          </table><br>
          
    
           
          <div class="address">${orderData[0].order_area + ', '}</div><br>
           
    
            <!-- token -->
           ${token}
    
          <br></br>
           <br></br>



<!-- Page Break -->
<div class="page-break"></div>

       <!-- Resto Control Info and Pricing customer -->
       <div style="display: flex; justify-content: center; align-items: center;">
      <img  src="${logo}" width="193" height="60" />
  </div><br>
      <div class="address">+91 82202 45554</div><br>

          
        <!-- token -->
    <div class="header">Token : ${token}</div>
      <div class="address"><h3>Grand Total : ₹ ${numeral(gTotal).format('0,0.00')}</h3></div><br>
    

      <br></br>
      
<!-- Page Break -->
<div class="page-break"></div>




      <!-- Resto Control Info and Pricing -->
       <div style="display: flex; justify-content: center; align-items: center;">
       <img  src="${logo}" width="193" height="60" />
  </div><br>
    
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>

      <hr></hr>
 <div class="address1">${cName}</div>
 <div class="address1">${cPhone}</div>
        ${cAddress}
       <div  class="address"></div><br>
      <hr></hr>
       <div class="address1">${orderData[0].order_area + ', '}</div>
       <div class="address1">Date : ${formattedDate}</div>
       <div class="address1">Bill No : #${ORDERID}</div>
        ${token}
  
<hr></hr>
      <table align="center" width="100%">
          <thead>
    <tr class="borders">
      <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content middle" width="10%">Amount</th>
    </tr>
  </thead>
            <tbody>
        ${orders.map(order => `
          <tr align="center">
         
            <td class="content left" width="70%">${order.order_item}</td>
            <td class="content middle" width="10%">${Number(order.order_price).toFixed(2)}</td>
            <td class="content middle" width="10%">${order.order_qty}</td>
            <td class="content right" width="20%">₹${Number(Number(order.order_price) * Number(order.order_qty)).toFixed(2)}</td>

          </tr>`).join('')}
            </tbody>

              <tfoot>

            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>
  
         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Sub Total</td>
            <td class="content right" width="10%">₹ ${Number(gTotal).toFixed(2)}</td>
          </tr>


           ${discountRow}

            ${deliveryRow}


           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹ ${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>



         
        
        </tfoot>
    </table><br>
     
     
   

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>
     
    </body>
  </html>
        `;
        }else if (orderData[0].order_area === 'Floor') {
      return `
       <html>
        <head>
          <title>Order Details</title>
          <link rel="stylesheet" href="styles.css">
            <title>Order Details</title>
            <style>
                 
                  body {
                    margin: 0px; 
                  }
                  .header {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 5px; 
                  }
                  .address {
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 0px; 
                  }
                  .content {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 10px;
                  }
                  .left {
                    text-align:left;
                  }
                  .right{
                    text-align:right;
                  }
                    @media print {
                 .page-break {
                   page-break-before: always; 
                   }
                  }
    
                </style>
        </head>
        <body>
    
    


      <!-- Resto Control Info and Pricing -->
       <div style="display: flex; justify-content: center; align-items: center;">
       <img  src="${logo}" width="193" height="60" />
  </div><br>
    
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>

      <hr></hr>
 <div class="address1">${cName}</div>
 <div class="address1">${cPhone}</div>
        ${cAddress}
       <div  class="address"></div><br>
      <hr></hr>
       <div class="address1">${orderData[0].order_area + ', '}</div>
       <div class="address1">Date : ${formattedDate}</div>
       <div class="address1">Bill No : #${ORDERID}</div>
        
  
<hr></hr>
      <table align="center" width="100%">
          <thead>
    <tr class="borders">
      <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content middle" width="10%">Amount</th>
    </tr>
  </thead>
            <tbody>
        ${orders.map(order => `
          <tr align="center">
         
            <td class="content left" width="70%">${order.order_item}</td>
            <td class="content middle" width="10%">${Number(order.order_price).toFixed(2)}</td>
            <td class="content middle" width="10%">${order.order_qty}</td>
            <td class="content right" width="20%">₹${Number(Number(order.order_price) * Number(order.order_qty)).toFixed(2)}</td>

          </tr>`).join('')}
            </tbody>

              <tfoot>

            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>
  
         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Sub Total</td>
            <td class="content right" width="10%">₹ ${Number(gTotal).toFixed(2)}</td>
          </tr>


           ${discountRow}

            ${deliveryRow}


           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹ ${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>



         
        
        </tfoot>
    </table><br>
     
     
   

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>
      
      
      <br></br>

<!-- Page Break -->
<div class="page-break"></div>





      <!-- Resto Control Info and Pricing -->
       <div style="display: flex; justify-content: center; align-items: center;">
       <img  src="${logo}" width="193" height="60" />
  </div><br>
    
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>

      <hr></hr>
 <div class="address1">${cName}</div>
 <div class="address1">${cPhone}</div>
        ${cAddress}
       <div  class="address"></div><br>
      <hr></hr>
       <div class="address1">${orderData[0].order_area + ', '}</div>
       <div class="address1">Date : ${formattedDate}</div>
       <div class="address1">Bill No : #${ORDERID}</div>
      
<hr></hr>
      <table align="center" width="100%">
          <thead>
    <tr class="borders">
      <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content middle" width="10%">Amount</th>
    </tr>
  </thead>
            <tbody>
        ${orders.map(order => `
          <tr align="center">
         
            <td class="content left" width="70%">${order.order_item}</td>
            <td class="content middle" width="10%">${Number(order.order_price).toFixed(2)}</td>
            <td class="content middle" width="10%">${order.order_qty}</td>
            <td class="content right" width="20%">₹${Number(Number(order.order_price) * Number(order.order_qty)).toFixed(2)}</td>

          </tr>`).join('')}
            </tbody>

              <tfoot>

            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>
  
         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Sub Total</td>
            <td class="content right" width="10%">₹ ${Number(gTotal).toFixed(2)}</td>
          </tr>


           ${discountRow}

            ${deliveryRow}


           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹ ${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>



         
        
        </tfoot>
    </table><br>
     
     
   

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>
     
    </body>
  </html>
     `;
        }else{
          return `
          <html>
        <head>
          <title>Order Details</title>
          <link rel="stylesheet" href="styles.css">
            <title>Order Details</title>
            <style>
                 
                  body {
                    margin: 0px; 
                  }
                  .header {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 5px; 
                  }
                  .address {
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 0px; 
                  }
                  .content {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 10px;
                  }
                  .left {
                    text-align:left;
                  }
                  .right{
                    text-align:right;
                  }
                    @media print {
                 .page-break {
                   page-break-before: always; 
                   }
                  }
    
                </style>
        </head>
        <body>
    
    
         <!-- Order Header and Address -->
          <div class="header">KOT-${userName}</div>
          <div class="address">${formattedDate}</div><br>
         <div class="address1">${orderData[0].order_name}</div>
    
          <table align="center">
            ${orders.map(order => `
              <tr >
                <td class="content left" width="85%" style="font-size: 16px">${order.order_item}</td>
                <td class="content right"  style="font-size: 16px">${order.order_qty}</td>
              </tr>`).join('')}
          </table><br>
          
    
           
          <div class="address">${orderData[0].order_area + ', '}</div><br>
           
    
            <!-- token -->
           ${token}
    
          <br></br>
           <br></br>



<!-- Page Break -->
<div class="page-break"></div>

       <!-- Resto Control Info and Pricing customer -->
       <div style="display: flex; justify-content: center; align-items: center;">
      <img  src="${logo}" width="193" height="60" />
  </div><br>
      <div class="address">+91 82202 45554</div><br>

          
        <!-- token -->
    <div class="header">Token : ${token}</div>
      <div class="address"><h3>Grand Total : ₹ ${numeral(gTotal).format('0,0.00')}</h3></div><br>
    

      <br></br>
      
     
    </body>
  </html>
        `;
    }
    
    
       
        
       
        
      };
    


  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
    <div class="card shadow-lg mx-2 mt-5 card-profile-bottom">
      <div class="card-body p-2">
        <div class="row gx-4">
          <div class="col-12">
               <div class="nav-wrapper position-relative end-0">
             
			  <div class="tab-content">
					
					 
        <div class="tab-pane fade show active" id="takeAway" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
           
              <div class="row">
              { orderData[0]?.order_area === "TakeAway"  || orderData[0]?.order_area === "Delivery" ?(<>
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Name</label>
                    <input type="text"  class="form-control"  value={cName}  placeholder="Enter Customer Name..." onChange={(e) => setcName(e.target.value)} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Phone Number</label>
                    <input type="number"  class="form-control" placeholder="Enter Customer Phone..." value={cPhone} onChange={(e) => {
        const value = e.target.value;

        // Allow only numbers and restrict length to 10
        if (/^\d{0,10}$/.test(value)) {
          setcPhone(value);
        }
      }}
      maxLength={10}
    />
                  </div>
                </div>  </> ):(<></>)}


                {orderData[0]?.order_area === "Delivery" ?(<>
                  <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address</label>
                   <input  class="form-control"  placeholder="Enter Customer Address..." value={cAddress} onChange={(e) => setcAddress(e.target.value)} />
                  </div>
                </div>
                </> ):(<></>)}



                <table className="col-md-12 mx-4 text-center text-sm" style={{ background: "#F9F9F9" }}>
                       <tbody>
                         {orders?.map((order) => (
                           <tr key={order.order_id}> 
                             <td width="70%">{order.order_item}</td>
                             <td width="8%">{order.order_qty}</td>
                             <td width="12%">{order.order_qty * order.order_price}</td>
                             <td width="10%">
                               <button
                                 className="btn btn-danger btn-m ms-0 my-2 me-2"
                                 onClick={() => handleDelete(order.id)}
                               >
                                 <i className="ni ni-basket"></i>
                               </button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                        <tfoot>
                         {grandTotal > 0 ?  (<> <tr>
                            <td></td>
                            <td colSpan="1">Total</td>
                           <td colSpan="1"> {grandTotal} </td>
                         </tr></>):(<></>)}
                        
                       </tfoot> 
                  </table>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                               {product.shortCode}
                        </option>
                    ))}
                    </datalist>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
               
    <div className="col-md-2">
      <div className="form-group">
        <label htmlFor="example-text-input" className="form-control-label">Price</label>
        <input
          type="number"
          className="form-control"
          name="price"
          placeholder="Enter Price..."
          value={qty * price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
    </div>
    <div className="col-md-2 my-4 py-2">
      <button class="btn btn-primary btn-sm ms-auto" onClick={() => additem()}>ADD</button>
    </div>
 

              </div>
           
              <div class="col-md-3">
                   <button class="btn btn-success btn-m " onClick={() => handleOrder()}>Confirm Order</button>
              </div>
             
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
              

                
            </div>
					   </div>

           
					   
				
					   
					   
          </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">

      <Footer />
    </div>
  </main>

  </div>
  )
}
export default Editbill;