import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from './sidebar';
import NavBar from './navbar';
import Footer from './footer';
import logo from '../assets/img//logos/FBH new logo.png';

function App() {
  

  const isPowerOn = false;

  const [hutData, setData] = useState(true);
 
    const fetchHutData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/hut/');
        setData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchHutData();
  }, []);


  const [rfTableData, setrfTableData] = useState(true);
    const fetchrfTableData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/roof_top/');
        setrfTableData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  ;

  useEffect(() => {
    fetchrfTableData();
  }, []);

  const [outerData, setouterData] = useState(true);
 
    const fetchOuterData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/outer/');
        setouterData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchOuterData();
  }, []);
  

  const [acHallData, setacHallData] = useState(true);
    const fetchAcHallData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/ac_hall/');
        setacHallData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchAcHallData();
  }, []);

  const togglePowers = {

  };

  const [PowerData, setPowerData] = useState(true);
  const Power = async (area, section) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/fbh-api/manage/table/', { section: section, area: area });
      setPowerData(response.data);
      console.log(response.data);
      fetchHutData();
      fetchrfTableData();
      fetchOuterData();
      fetchAcHallData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };



  const [AddData, setAddData] = useState(true);
  const  Add_section = async (area) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/fbh-api/manage/area/', { area: area });
      setAddData(response.data);
      fetchHutData();
      fetchrfTableData();
      fetchOuterData();
      fetchAcHallData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };
  
  const [DeleteData, setDeleteData] = useState(true);
  const Delete = async (area, section) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/fbh-api/manage/delete/', { section: section, area: area });
      setDeleteData(response.data);
      console.log(response.data);
      fetchHutData();
      fetchrfTableData();
      fetchOuterData();
      fetchAcHallData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };
    
  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="card">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <h5 class="ftext-sm mb-0 text-uppercase font-weight-bold">
                    Resto Control
                    </h5>
                    <p class="ftext-sm mb-0 p-4">Table Manage</p>
                </div>
                </div>
                <div class="col-4 text-end">
            <div class="avatar avatar-xl position-relative">
              <img src={logo} alt="profile_image h-100" class="w-100 border-radius-lg shadow-sm"/>
            </div>
              </div>
            </div>
          </div>
          </div>
        </div>

       
    </div>
 
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">OUTER</h6>
      {outerData && outerData.data && outerData.data.map(outer => (
		
        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4" key={outer.table_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                 <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{outer.table_name}</p>
                 </div>
                </div>
                <div class="col-4 text-end">
                <a class="btn btn-link text-danger text-gradient text-sm" href="javascript:;" style={{marginTop: "-10px"}}>
  <i class="far fa-trash-alt text-lg" onClick={() => Delete('outer_table', outer.table_id)}></i></a>
                </div>
                <div class="col-12 text-center">
                <button
                 className={`icon icon-shape bg-gradient-${outer.table_status == 'yes' ? 'success' : 'danger'} shadow-${outer.table_status == 'yes' ? 'success' : 'danger'} text-center rounded-circle t-1 ${
                  outer.table_status == 'yes' ? 'font-weight-bolder' : ''
            }`}
            onClick={() => Power('outer_table', outer.table_id)}
            style={{
                border: 'none', // Remove border
                outline: 'none', // Remove outline
                // Add any other styles you need
              }} >
            {outer.table_status == 'yes' ? (
    <i
      className="fas fa-check-circle text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  ) : (
    <i
      className="fa fa-power-off text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  )}
            </button>
          </div> 
                <p class="mb-0 text-sm mt-2">
                      <span class="text-success text-sm font-weight-bolder" >{outer.table_date}</span>  
                    </p>
              </div>
            </div>
          </div>
        </div>
    ))}

<div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4">
    <div class="card" onClick={() => Add_section('outer_table')}>
      <div class="card-body p-5">
        <div class="row">
          <div class="col-12 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle mt-2">
            <i class="fa fa-plus text-center text-lg opacity-10" style={{ color: 'white', position: 'relative', top: '12px' }} aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
       
    </div>
	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;