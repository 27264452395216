import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [orderData, setOrderData] = useState(null);
  const [date, setDate] = useState('');
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [takeawayAmount, setTakeawayAmount] = useState(0);
  const [dineAmount, setDineAmount] = useState(0);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0'); 
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const year = currentDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/sales/');
        if(response.data.status === 'success'){
            setOrderData(response.data);
            const currentDate = getCurrentDate();
            setDate(currentDate);
            
            const delivery = response.data.data.dine.orderData.filter(orders => orders.area === 'Delivery');
            let delveryAmount = 0;
            delivery.map(orders => {
                delveryAmount += Number(orders.amount);
            })
            setDeliveryAmount(delveryAmount);

            const takeaway = response.data.data.dine.orderData.filter(orders => orders.area === 'TakeAway');
            let takeawayAmount = 0;
            takeaway.map(orders => {
                takeawayAmount += Number(orders.amount);
            })
            setTakeawayAmount(takeawayAmount);

            const dineFloor = response.data.data.dine.orderData.filter(orders => orders.area === 'Floor' || orders.area === 'Outer_Table');
            let floorAmount = 0;
            dineFloor.map(orders => {
                floorAmount += Number(orders.amount);
            })
            setDineAmount(floorAmount);


        }else{
            console.error(response.data);
        }
      } catch (error) {
        console.error(error);
      } 
    };
     
    fetchData();
  }, []);

  const customDate = async (e) => {
    const date = e.target.value;  
    const formattedDate = date.replace(/-/g, '/');
    const dateParts = formattedDate.split('/');
    const formatted = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    setDate(formatted);
    const formData = {date: formattedDate};

    try{
        const response = await axios.post('https://projects.sninfoserv.com/fbh-api/sales/', formData);
        if(response.data.status === 'success'){
            setOrderData(response.data);

            const delivery = response.data.data.dine.orderData.filter(orders => orders.area === 'Delivery');
            let delveryAmount = 0;
            delivery.map(orders => {
                delveryAmount += Number(orders.amount);
            })
            setDeliveryAmount(delveryAmount);

            const takeaway = response.data.data.dine.orderData.filter(orders => orders.area === 'TakeAway');
            let takeawayAmount = 0;
            takeaway.map(orders => {
                takeawayAmount += Number(orders.amount);
            })
            setTakeawayAmount(takeawayAmount);

            const dineFloor = response.data.data.dine.orderData.filter(orders => orders.area === 'Floor' || orders.area === 'Outer_Table');
            let floorAmount = 0;
            dineFloor.map(orders => {
                floorAmount += Number(orders.amount);
            })
            setDineAmount(floorAmount);
        }else{
            console.error(response.data);
        }

    }catch(error){
        console.error(error);
    }

  };

  const downloadExcel = () => {
    const fileName = `daily_report_${date}.xlsx`;

    const columns = ["S.No", "Type", "Time", "Items", "amount", "Customer", "Phone", "Address"]; 

    const data = [columns];
    if (orderData?.data?.dine?.orderData.length > 0) {
       
        orderData?.data?.dine?.orderData.forEach((orders, index) => {
            data.push([
                index+1 || '', 
                orders.area || '', 
                orders.start_time || '', 
                orders.orderItems.map((items, index)=>  `${index!== 0 ? ', ': ''} ${items.item} x ${items.qty}`) || '',
                Number(orders.amount).toFixed(2) || '',
                orders.area === 'Delivery' || orders.area === 'TakeAway' ? orders.section : '' || '',
                orders.area === 'Delivery' || orders.area === 'TakeAway' ? orders.phone : '' || '',
                orders.area === 'Delivery' ?  orders.address : '' || ''
            ]);
        });
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    XLSX.writeFile(wb, fileName);
}

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}
    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">All Orders</p>
                
                        <h5 class="font-weight-bolder">{orderData?.data?.dine?.orderData?.length}</h5>
                        <h6>₹ {Number(orderData?.data?.dine?.order_amount).toFixed(2)}</h6>
                  
                   
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                    <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Deliveries</p>
                   
                        <h5 class="font-weight-bolder">{orderData?.data?.dine?.orderData.filter(orders => orders.area === 'Delivery').length}</h5>
                        <h6>₹ {Number(deliveryAmount).toFixed(2)}</h6>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                    <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Take-Away</p>
                        <h5 class="font-weight-bolder">{orderData?.data?.dine?.orderData.filter(orders => orders.area === 'TakeAway').length}</h5>
                        <h6>₹ {Number(takeawayAmount).toFixed(2)}</h6>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                    <i class="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Dine In</p>
                        <h5 class="font-weight-bolder">{orderData?.data?.dine?.orderData.filter(orders => orders.area === 'Outer_Table' || orders.area === 'Floor').length}</h5>
                        <h6>₹ {Number(dineAmount).toFixed(2)}</h6>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                    <i class="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Orders - {date}
              <button class="btn btn-success btn-sm mt-1" style={{float:'right', marginLeft:'20px'}} onClick={downloadExcel}> <i class="fa fa-download"></i></button>
                 <input type="date" class="form-control" style={{width: '150px', float: 'right'}} onChange={(e)=> customDate(e)} />
                 </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" width="5%">S.No</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Type</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Time</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">ITEMS</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                
                {orderData?.data?.dine?.orderData?.slice().reverse().map((orders, index) => (

                    <tr>
					  <td> <span class="text-xs font-weight-bold">{index+1} </span></td>
                      <td>
                        <span class="text-xs font-weight-bold">{orders.area}<br />
                           {/* <small style={{fontSize: '8px'}}> 
                            {orders.area === 'Delivery' || orders.area === 'TakeAway' && orders.section}<br />
                            {orders.area === 'Delivery' || orders.area === 'TakeAway' && orders.phone}
                            {orders.area === 'Delivery' && ', ' + orders.address}
                           </small> */}
                        </span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{orders.start_time}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold"><small>{orders.orderItems.map((items, index)=>  `${index!== 0 ? ', ': ''} ${items.item} x ${items.qty}`)}</small></span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">₹ {Number(orders.amount).toFixed(2)}</span>
                      </td>
                    </tr>
                    
                    ))}
                    {orderData?.data?.dine?.orderData.length === 0 && <tr><td colspan="8" style={{textAlign: 'center', padding: '40px'}}>No Data</td></tr>}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
		
        
	
	  

	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;